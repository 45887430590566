.tl{
    width: 80%;
    margin: 0 auto;
}
.allitems {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1.5rem;
  margin-top: 1.5rem;
}

.searchBar {
  position: relative;
  width: 300px;
  margin-top: 2rem;
  border-style: groove;
  border-color: rgba(1, 91, 117,0.75);
  border-radius: 10px;
  
}
.searchBar input {
  width: 100%;
  font-family: 'RobotoMono Regular';
  font-size: 1.4rem;
  padding: 0.2rem 1rem;
  border-radius: 6px;
  outline: none;
  border: none;
}
.searchIcon {
  font-size: 2.4rem;
  margin-top: 0;
  margin-left: 15px;
  position: absolute;
  background-color: #015B75;
  border-radius: 50%;
}
.searchBar .searchIcon path {
  color: #fff;
}

.backdrop{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0,0,0,0.5);
}

.backdrop img{
  display: block;
  max-width: 60%;
  max-height: 80%;
  margin: 16vh auto;
  box-shadow: 3px 5px 7px rgba(0,0,0,0.5);
  border: 3px solid white;
}

@media only screen and (max-width: 630px) {
  .searchBar{
    width: 90%;
  }
}

@media only screen and (max-width: 1300px) {
  .backdrop img{
    max-width: 70%;
    max-height: 90%;
  }
}

@media only screen and (max-width: 1000px) {
  .backdrop img{
    max-width: 80%;
    max-height: 90%;
  }
}

@media only screen and (max-width: 800px) {
  .backdrop img{
    max-width: 90%;
    max-height: 90%;
  }
}

@media only screen and (max-width: 600px) {
  .backdrop img{
    max-width: 92%;
    max-height: 95%;
  }
}