.container{
    padding: 0 12px 0 12px;
}

.my-2{
    margin-top: 1rem!important;
}
.chanlogo p{
    text-align: center;
    font-size: 25px;
}

.chanlogo p a img{
    width: 28px;
}

.title-text{
    width: 30%;
    background-color: #015B75;
    margin: 0 auto;
    border-radius: 10px;
    letter-spacing: 2px;
}

.title-text h1{
    color: white;
}

@media (max-width:991px){
    .title-text{
        width: 80%;
    }
}