.heading{
    font-size: 2.0rem;
    margin-bottom: 12px;
    color: white;
}
ul{
    padding-left: 0;
    margin-bottom: 0;
}
li {
    margin-bottom: 0;
    list-style: none;
    text-decoration: none;
    color: white;
}
a {
    font-size: 16px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    text-decoration: none;
    color: white;
}
a:hover{
    color: white;
}