header{
    width: 100%;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(254,254,254,0.92);
    position: fixed;
    z-index: 100;
    box-shadow: 0 1px 3px rgba(0,0,0,0.2);
}

.logo{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif, cursive;
    letter-spacing: 2px;
    font-size: 2rem;
    color: #015b75;
    font-weight: bold;
    padding: 0 5%;
}

header ul{
    list-style: none;
    margin: 0 30px;
    display: flex;
    align-items: baseline;
}

header li{
    margin-left: 20px;
}

header a{
    text-decoration: none;
    font-weight: 500;
    font-size: 1.3rem;
    letter-spacing: 0.8px;
    color: #015b75;
}

header a:hover,
header a:active,
header a.active{
    color: rgba(255, 20, 147, 0.9);
}

.btn{
    display: none;
    position: absolute;
    right: 20px;
    top: 0px;
    padding: 5px;
    font-weight: bold;
    font-size: 2rem;
    color: #015b75;
}

@media screen and (max-width: 900px) {
    header a{
        
    }
}

@media screen and (max-width: 800px) {

    nav{
        width: 100%;
    }

    header ul{
        flex-direction: column;
        height: 20px;
        width: 100%;
        align-items: flex-end;
    }
    header li:nth-child(1){
        border-top: 1px solid rgba(255,255,255,0.1);
    }
    header li{
        width: 100%;
        border-top:1px solid rgba(255,255,255,0.1);
        text-align: center;
        margin-right: 0px;
        padding: 20px 0;
        background-color: rgba(254,254,254,0.92);
    }
    .btn{
        display: block;
        color: #015b75;
    }
    .btn:hover{
        color:#015b75;
    }
}
