
.img {
  width: 100%;
  height: 250px;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  border: 3px solid var(--gray-2);
}

.img img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.info {
  margin-top: 10px;
  background-color: var(--deep-dark);
  padding: 0 10px;
  border-radius: 10px;
}
.title {
  font-size: 25px;
}
.desc {
  font-size: 15px;
  font-family: 'RobotoMono Regular';
  margin-top: 8px;
}

.desc p{
  margin-bottom: 0;
}

@media only screen and (max-width: 1300px) {
  .img {
    height: 245px;
  }
}

@media only screen and (max-width: 1000px) {
  .img {
    height: 240px;
  }
}

@media only screen and (max-width: 700px) {
  .img {
    height: 235px;
  }
}

@media only screen and (max-width: 600px) {
  .img {
    height: 230px;
  }
}

@media only screen and (max-width: 500px) {
  .img {
    height: 225px;
  }
}

@media only screen and (max-width: 400px) {
  .img {
    height: 220px;
  }
}
