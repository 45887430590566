.title-text{
    margin-top: 5rem;
}

.gallery{
    margin-top: 15px;
   column-count: 4;
   column-width: 25%;
   -webkit-column-count: 4;
   -webkit-column-width: 25%;
   -moz-column-count: 4;
   -moz-column-width: 25%;
   padding: 0 12px 0 12px;
}

.gallery .pics{
    -webkit-transision:all 350ms ease;
    transition: all 350ms ease;
    cursor: pointer;
    margin-bottom: 12px;
    overflow: hidden;
    transition: .3s ease box-shadow;
    position: relative;
}

.gallery .pics:hover{
    box-shadow: 0px 0px 20px 3px #00000042;
}

.gallery .pics .desc{
    margin-top: 0;
    z-index: 11;
    opacity: 0;
    padding: auto;
    position: absolute;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.35);
}
.gallery .pics .desc:hover{
    opacity: 1;
}

.gallery .pics .imgbox{
    z-index: 5;
}

.gallery .pics .desc .middle{
    vertical-align: middle;
    margin: 60px auto;
}

.gallery .pics .desc h2{
    vertical-align:middle;  
    text-align: center;
    color: #fff;
    font-family: 'Montserrat Bold';
    font-size: 30px;
    margin-top: 5px;
    
}

.gallery .pics .desc p{
    vertical-align:middle;  
    text-align: center;
    color: #fff;
    font-size: 20px;
    font-family: 'Montserrat Bold';
    margin-bottom: 5px;
    
}

@media (max-width:1300px){
    .gallery{
        column-count: 3;
        -webkit-column-count: 3;
        -moz-column-count: 3;
    }
    .gallery .pics .desc h2{
        font-size: 28px;
    }
    
    .gallery .pics .desc p{
        font-size: 19px;
    }
}


@media (max-width:1000px){
    .gallery{
        column-count: 2;
        -webkit-column-count: 2;
        -moz-column-count: 2;
    }
    .gallery .pics .desc .middle{
        margin: 90px auto;
    }
    .gallery .pics .desc h2{
        font-size: 26px;
    }
    
    .gallery .pics .desc p{
        font-size: 18px;
    }
}

@media (max-width:800px){
    .gallery .pics .desc .middle{
        margin: 70px auto;
    }
    .gallery .pics .desc h2{
        font-size: 24px;
    }
    
    .gallery .pics .desc p{
        font-size: 16px;
    }
}

@media (max-width:600px){
    .gallery .pics .desc .middle{
        margin: 50px auto;
    }

    .gallery .pics .desc h2{
        font-size: 22px;
    }
    
    .gallery .pics .desc p{
        font-size: 14px;
    }
}


@media (max-width:500px){
    .gallery{
        column-count: 1;
        column-width: 100%;
        -webkit-column-count: 1;
        -webkit-column-width: 100%;
        -moz-column-count: 1;
        -moz-column-width: 100%;
     }
     .gallery .pics .desc .middle{
        margin: 80px auto;
    }
     .gallery .pics .desc h2{
        font-size: 26px;
    }
    
    .gallery .pics .desc p{
        font-size: 18px;
    }
}

.model{
    width: 100%;
    height: 100vh;
    position: fixed;
    top:0;
    left: 0;
    display: flex;
    justify-content:center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.75);
    transition: opacity .4s ease, visibility .4s ease, transform .5s ease-in-out;
    visibility: hidden;
    opacity: 0;
    transform:scale(0);
    overflow: hidden;
    z-index: 999;
}

.model.open{
    visibility: visible;
    opacity: 1;
    transform: scale(1);
}

.model img{
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 90%;
    display: block;
    line-height: block;
    line-height: 0;
    box-sizing: border-box;
    padding: 20px 0 20px;
    margin: 0 auto;
}

.close{
    position: fixed;
    top: 20px;
    right: 20px;
    width:40px;
    height:40px;
    background: CloseIcon;
    padding: 5px;
    cursor: pointer;
}

.container{
    width: 100%;
}

.container .cpryt{
    text-align: center;
    color: #015b75;
}

.container .cpryt a{
    text-decoration: none;
}