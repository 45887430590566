.ftcontainer{
    margin-top: 1rem;
    padding-top: 1rem;
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 1rem;
    background-color: #015b75;
}
.fontainer{
    display: flex;
    gap: 2rem;
    color: white;
}
.footer__col1{
    flex: 2;
}
.footer__col2, .footer__col3, .footer__col4{
    flex: 1;
}
.footer__col1_title{
    font-size: 3.5rem;
    margin-bottom: 20px;
}

@media only screen and (max-width: 800px) {
    .fontainer{
        flex-direction: column;
        gap: 0;
    }
    .footer__col1{
        width: 100%;
        text-align: center;
    }
    .footer__col2{
        display: none;
    }
  }